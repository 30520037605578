<template>
  <div class="text-black text-base overflow-hidden max-w-screen">
    <header>
      <TheAppBar />
      <!-- <TheLoginButton />
    <TheViewButton /> -->
    </header>
    <main class="container mx-auto px-4">
      <router-view></router-view>
    </main>
    <TheFooter />
  </div>
</template>

<script setup>
import TheAppBar from './components/TheAppBar.vue'
import TheLoginButton from './components/TheLoginButton.vue'
import TheViewButton from './components/TheViewButton.vue'
import TheFooter from './components/TheFooter.vue'
import axios from 'axios'

axios.defaults.baseURL = import.meta.env.VITE_HOST + '/api'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
</script>

<style>
@import url('@wikimedia/codex/dist/codex.style.css');

header {
  display: flex;
  flex-direction: row;

  font-size: larger;
}

main {
  max-width: 1600px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
</style>
