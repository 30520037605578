import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', name: 'home', component: () => import('../views/HomeView.vue') },
  { path: '/about', name: 'about', component: () => import('../views/AboutView.vue') },
  { path: '/help', name: 'help', component: () => import('../views/HelpView.vue') },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/mention-legales',
    name: 'mention-legales',
    component: () => import('../views/MentionLegales.vue'),
  },
  {
    path: '/record',
    component: () => import('../views/RecordWizardView.vue'),
    children: [
      {
        path: '',
        name: 'record-wizard.locutor',
        component: () => import('../views/record-wizard/LocutorStep.vue'),
      },
      {
        path: 'language',
        name: 'record-wizard.language',
        component: () => import('../views/record-wizard/LanguageStep.vue'),
      },
      {
        path: 'hardware-check',
        name: 'record-wizard.hardware-check',
        component: () => import('../views/record-wizard/HardwareCheckStep.vue'),
      },
      {
        path: 'word-list',
        name: 'record-wizard.word-list',
        component: () => import('../views/record-wizard/WordListStep.vue'),
      },
      {
        path: 'record',
        name: 'record-wizard.record',
        component: () => import('../views/record-wizard/RecordStep.vue'),
      },
      {
        path: 'review',
        name: 'record-wizard.review',
        component: () => import('../views/record-wizard/ReviewStep.vue'),
      },
    ],
  },
  {
    path: '/share/:listId', component: () => import('../views/record-wizard/ShareWordList.vue')
  },
  { path: '/my-upload-batches', component: () => import('../views/UploadBatchesView.vue') },
  { path: '/my-upload-batches/:id', component: () => import('../views/UploadBatchView.vue') },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

export default router
