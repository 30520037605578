<template>
  <div class="lingualibre-login">
    <CdxButton
      v-if="!isAuthenticated"
      action="progressive"
      weight="quiet"
      data-testid="login"
      @click="goToLogIn"
    >
      <CdxIcon :icon="cdxIconLogIn" />Log in
    </CdxButton>
    <div v-else>
      <CdxButton action="destructive" weight="quiet" @click="logOut">
        <CdxIcon :icon="cdxIconLogOut" />Log out
      </CdxButton>
    </div>
  </div>
</template>

<script setup>
import { CdxButton, CdxIcon } from '@wikimedia/codex'
import { cdxIconLogIn, cdxIconLogOut } from '@wikimedia/codex-icons'
import VueCookies from 'vue-cookies'

const isAuthenticated = VueCookies.get('sessionid') || ''

const goToLogIn = function () {
  window.location.replace(import.meta.env.VITE_HOST + '/oauth/login/mediawiki')
}

const logOut = function () {
  VueCookies.remove('sessionid')
  // reload page
  window.location.reload()
}
</script>

<style scoped>
.lingualibre-login {
  flex: auto;
}
</style>
