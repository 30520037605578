<template>
  <nav class="text-base shadow-md w-full">
    <transition name="slide">
      <MobileNav v-show="showMobileNav" class="sm:hidden" :toggle-navbar="toggleNavbar" />
    </transition>

    <div class="flex justify-between pt-0 pb-3 sm:pb-5 gap-x-10 container mx-auto px-4">
      <a class="flex flex-col text-black" href="/">
        <img src="/favicon.ico" alt="" class="w-36" />
        <p class="text-2xl text-center -mt-6 font-semibold -ml-2.5 font-serif">
          <span>Lingua </span>
          <span class="text-primary-blue">Libre</span>
        </p>
      </a>
      <div class="flex flex-col flex-1 items-end mt-7">
        <div class="flex gap-x-4 items-center">
          <div class="flex relative items-center">
            <input
              type="text"
              placeholder="Search"
              class="border sm:w-[100%] hidden sm:block px-3 py-2"
            />
            <span class="hidden md:block absolute right-2 w-6">
              <CdxIcon :icon="cdxIconSearch" />
            </span>
          </div>
          <select
            id=""
            name=""
            class="border px-3 py-2.5 md:pl-7 font-medium hidden sm:block text-slate-700"
          >
            <option value="english">English</option>
          </select>
          <button class="bg-primary-blue text-white px-3 py-2 flex gap-x-2 items-center">
            <span class="scale-90"> <Mic /></span>
            <span class="font-bold hidden xs:inline"> Record </span>
          </button>
          <TheLoginButton />
          <!-- <CdxIcon :icon="cdxIconUserAvatar" class="scale-125 ml-0.5 min-w-6" /> -->
        </div>
        <hr class="w-full opacity-60 mt-3 mb-5 sm:mt-5 sm:mb-7" />
        <span class="scale-125 sm:hidden" @click="toggleNavbar">
          <CdxIcon :icon="cdxIconMenu" />
        </span>
        <div
          class="hidden sm:flex gap-x-5 gap-y-3 font-semibold flex-wrap self-start ml-3 lg:ml-14"
        >
          <a href="/record" class="text-black">Record Wizard</a>
          <span>Discussion</span>
          <span>Statistics</span>
          <span>Sound Library</span>
          <a href="/help" class="text-black">Help</a>
          <span>Datasets</span>
          <a href="/about" class="text-black">About</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue'
import { CdxIcon } from '@wikimedia/codex'
import { cdxIconUserAvatar, cdxIconMenu, cdxIconSearch } from '@wikimedia/codex-icons'
import Mic from '../components/icons/Mic.vue'
import MobileNav from '../components/MobileNav.vue'
import TheLoginButton from '../components/TheLoginButton.vue'

const showMobileNav = ref(false)

const toggleNavbar = () => {
  showMobileNav.value = !showMobileNav.value
}
</script>

<style scoped>
/* Built-in sliding transition styles */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
