import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueCookies from 'vue-cookies'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VueCookies)

// setup FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faMastodon } from '@fortawesome/free-brands-svg-icons'
library.add(faTwitter, faMastodon)

app.mount('#app')
